import React from 'react';
import './header_center.css';

function Header_center() {
    return (
        <div className="Header_center">
            <p>CLUB HUB</p>
        </div>
    )
}

export default Header_center;